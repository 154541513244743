// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'email'
  }, {
    prop: 'name'
  }, {
    prop: 'last_name'
  }, {
    prop: 'phone_number'
  }, {
    prop: 'user_group'
  }]
}
